import { Auth0 } from '@/auth';
import { UserRole } from '@/models/User';
import { createWebHistory, createRouter } from 'vue-router';

const routes = [
  {
    path: '/profile',
    name: 'profile',
    beforeEnter: Auth0.routeGuard,
    component: () => import('../views/ProfilePage.vue'),
    meta: {
      breadcrumb: [{ parent: 'breadcrumb.patientList', label: 'topbar-menu.my-profile' }],
    },
  },
  {
    path: '/users',
    name: 'users',
    beforeEnter: Auth0.routeGuard,
    component: () => import('../views/UsersPage.vue'),
    meta: {
      grants: [UserRole.ADMIN],
      breadcrumb: [{ parent: 'breadcrumb.patientList', label: 'topbar-menu.user-management' }],
    },
  },
  {
    path: '/users-audit',
    name: 'users-audit',
    beforeEnter: Auth0.routeGuard,
    component: () => import('../views/UsersAuditPage.vue'),
    meta: {
      grants: [UserRole.ADMIN],
      breadcrumb: [{ parent: 'breadcrumb.patientList', label: 'topbar-menu.user-audit' }],
    },
  },
  {
    path: '/account-settings',
    name: 'account-settings',
    beforeEnter: Auth0.routeGuard,
    component: () => import('../views/AccountPage.vue'),
    meta: {
      grants: [UserRole.ADMIN],
      breadcrumb: [{ parent: 'breadcrumb.patientList', label: 'topbar-menu.account-settings' }],
    },
  },
  {
    path: '/',
    name: 'dashboard',
    beforeEnter: Auth0.routeGuard,
    component: () => import('../views/DashboardPage.vue'),
    meta: {
      breadcrumb: [{ parent: 'breadcrumb.dashboard', label: 'breadcrumb.dashboard' }],
    },
  },
  {
    path: '/patients',
    name: 'patients',
    beforeEnter: Auth0.routeGuard,
    component: () => import('../views/PatientsPage.vue'),
    meta: {
      breadcrumb: [{ parent: 'breadcrumb.patientList', label: 'breadcrumb.patientList' }],
    },
  },
  {
    path: '/patient/:patientId',
    component: () => import('../views/PatientDetailPage.vue'),
    beforeEnter: Auth0.routeGuard,
    children: [
      {
        path: 'info',
        component: () => import('../components/PatientInformation.vue'),
        beforeEnter: Auth0.routeGuard,
        meta: {
          breadcrumb: [{ parent: 'breadcrumb.patientList', label: 'breadcrumb.information' }],
        },
      },
      {
        path: 'plans-list',
        component: () => import('../components/plans/PatientPlansView.vue'),
        beforeEnter: Auth0.routeGuard,
        meta: {
          breadcrumb: [{ parent: 'breadcrumb.patientList', label: 'breadcrumb.plan' }],
        },
      },
      {
        path: 'plan-detail/:planId',
        name: 'plan-detail',
        component: () => import('../components/plans/PatientPlanDetails.vue'),
        beforeEnter: Auth0.routeGuard,
        meta: {
          breadcrumb: [{ parent: 'breadcrumb.plan', label: '' }],
        },
      },
      {
        path: 'patient-data',
        component: () => import('../components/PatientData.vue'),
        beforeEnter: Auth0.routeGuard,
        meta: {
          breadcrumb: [{ parent: 'breadcrumb.patientList', label: 'breadcrumb.patientData' }],
        },
        children: [
          {
            path: 'anthropometric-data',
            name: 'patient-anthropometric-data',
            component: () => import('@/components/patient/AnthropometricData.vue'),
          },
          {
            path: 'medical-history',
            name: 'patient-medical-history',
            component: () => import('@/components/patient/MedicalHistory.vue'),
          },
          {
            path: 'healz-objective',
            name: 'patient-healz-objective',
            component: () => import('@/components/patient/HealzObjective.vue'),
          },
          {
            path: 'habits',
            name: 'patient-habits',
            component: () => import('@/components/patient/Habits.vue'),
          },
        ],
      },
      {
        path: 'follow-up',
        component: () => import('../components/wearables/WearablesPage.vue'),
        beforeEnter: Auth0.routeGuard,
        meta: {
          breadcrumb: [{ parent: 'breadcrumb.patientList', label: 'breadcrumb.followUp' }],
        },
        children: [
          {
            path: 'activity',
            name: 'follow-up-activity',
            component: () => import('@/components/wearables/activity/Activity.vue'),
          },
          {
            path: 'activity/:eventId/events',
            name: 'follow-up-activity-event-detail',
            component: () => import('@/components/wearables/common/EventDetail.vue'),
          },
          {
            path: 'sleep',
            name: 'follow-up-sleep',
            component: () => import('@/components/wearables/sleep/Sleep.vue'),
          },
          {
            path: 'nutrition',
            name: 'follow-up-nutrition',
            component: () => import('@/components/wearables/nutrition/Nutrition.vue'),
          },
          {
            path: 'nutrition/:eventId/events',
            name: 'follow-up-nutrition-event-detail',
            component: () => import('../components/cgm/CgmEvent.vue'),
          },
          // {
          //   path: 'medical-history',
          //   name: 'patient-medical-history',
          //   component: () => import('@/components/patient/MedicalHistory.vue'),
          // },
          // {
          //   path: 'healz-objective',
          //   name: 'patient-healz-objective',
          //   component: () => import('@/components/patient/HealzObjective.vue'),
          // },
          // {
          //   path: 'habits',
          //   name: 'patient-habits',
          //   component: () => import('@/components/patient/Habits.vue'),
          // },
        ],
      },
      {
        path: 'medical-tests',
        name: 'medical-tests',
        component: () => import('../components/PatientMedicalTestView.vue'),
        beforeEnter: Auth0.routeGuard,
        meta: {
          breadcrumb: [{ parent: 'breadcrumb.patientList', label: 'breadcrumb.medicalTests' }],
        },
      },
      {
        path: 'notes',
        component: () => import('../components/notes/PatientNotesView.vue'),
        beforeEnter: Auth0.routeGuard,
        meta: {
          breadcrumb: [{ parent: 'breadcrumb.patientList', label: 'breadcrumb.notes' }],
        },
      },
      {
        path: 'nutrition/plans',
        component: () => import('../components/nutrition/plans/PatientNutritionalPlanView.vue'),
        beforeEnter: Auth0.routeGuard,
        meta: {
          breadcrumb: [{ parent: 'breadcrumb.patientList', label: 'breadcrumb.plans' }],
        },
      },
      {
        path: 'nutrition/plans/:nutritionalPlan',
        component: () => import('../components/nutrition/plans/NutritionalPlanDetail.vue'),
        beforeEnter: Auth0.routeGuard,
        meta: {
          breadcrumb: [{ parent: 'breadcrumb.patientList', label: '' }],
        },
        children: [
          {
            path: 'patient-data',
            name: 'nutritionalplans-patient-data',
            component: () => import('../components/nutrition/plans/NutritionalPlanPatientData.vue'),
          },
          {
            path: 'food-distribution',
            name: 'nutritionalplans-food-distribution',
            component: () => import('../components/nutrition/plans/NutritionalPlanFoodDistribution.vue'),
          },
          {
            path: 'menu-template',
            name: 'nutritionalplans-menu-template',
            component: () => import('../components/nutrition/plans/NutritionalPlanMenuTemplate.vue'),
          },
        ],
      },
      {
        path: 'nutrition/menus',
        component: () => import('../components/nutrition/menus/PatientMenuView.vue'),
        beforeEnter: Auth0.routeGuard,
        meta: {
          breadcrumb: [{ parent: 'breadcrumb.patientList', label: 'breadcrumb.menus' }],
        },
      },
      {
        path: 'medical-test/calorimetry/:medicalTest',
        component: () => import('../components/CalorimetryMedicalTestDetail.vue'),
        beforeEnter: Auth0.routeGuard,
        meta: {
          grants: [UserRole.SPECIALIST],
          breadcrumb: [{ parent: 'breadcrumb.medicalTests', label: '' }],
        },
      },
      {
        path: 'medical-test/cgm/:medicalTest',
        component: () => import('../components/CgmMedicalTestDetail.vue'),
        beforeEnter: Auth0.routeGuard,
        meta: {
          grants: [UserRole.SPECIALIST],
          breadcrumb: [{ parent: 'breadcrumb.medicalTests', label: '' }],
        },
        children: [
          {
            path: 'follow-up',
            name: 'medicaltest-detail-follow-up',
            component: () => import('../components/cgm/CgmFollowUp.vue'),
          },
          {
            path: 'events',
            name: 'medicaltest-detail-events',
            component: () => import('../components/cgm/CgmEvents.vue'),
          },
          {
            path: 'patient-info',
            name: 'medicaltest-detail-patient-info',
            component: () => import('../components/cgm/CgmPatientInfo.vue'),
          },
          {
            path: 'final-analysis',
            name: 'medicaltest-detail-final-analysis',
            component: () => import('../components/cgm/CgmAnalysis.vue'),
          },
        ],
      },
      {
        path: 'medical-test/cgm/:medicalTest/event/:eventId',
        name: 'event-detail',
        component: () => import('../components/cgm/CgmEvent.vue'),
        beforeEnter: Auth0.routeGuard,
        meta: {
          breadcrumb: [{ parent: 'breadcrumb.medicalTests', label: '' }],
        },
      },
      {
        path: 'configuration',
        component: () => import('../components/PatientConfiguration.vue'),
        beforeEnter: Auth0.routeGuard,
        meta: {
          breadcrumb: [{ parent: 'breadcrumb.patientList', label: 'breadcrumb.configuration' }],
        },
        children: [
          {
            path: 'patientData',
            name: 'patient-patient-data',
            component: () => import('@/components/configuration/PatientData.vue'),
          },
          {
            path: 'specialists',
            name: 'patient-specialists',
            component: () => import('@/components/configuration/AssociatedSpecialists.vue'),
          },
          {
            path: 'patientAccountState',
            name: 'patient-patient-account-state',
            component: () => import('@/components/configuration/PatientAccountState.vue'),
          },
        ],
      },
      {
        path: 'supplements',
        component: () => import('@/components/supplements/PatientSupplementsView.vue'),
        beforeEnter: Auth0.routeGuard,
        meta: {
          breadcrumb: [{ parent: 'breadcrumb.patientList', label: 'breadcrumb.supplements' }],
        },
      },
      {
        path: 'supplements/:supplementId',
        component: () => import('@/components/supplements/PatientSupplementDetail.vue'),
        beforeEnter: Auth0.routeGuard,
        meta: {
          breadcrumb: [{ parent: 'breadcrumb.patientList', label: '' }],
        },
      },
    ],
  },
  {
    path: '/chats',
    name: 'chats',
    beforeEnter: Auth0.routeGuard,
    component: () => import('../views/ChatsPage.vue'),
    meta: {
      breadcrumb: [{ parent: 'breadcrumb.chats', label: 'breadcrumb.chats' }],
    },
  },
  {
    path: '/supplementation',
    name: 'supplementation',
    beforeEnter: Auth0.routeGuard,
    component: () => import('../views/SupplementationPage.vue'),
    meta: {
      breadcrumb: [{ parent: 'breadcrumb.supplementation', label: 'breadcrumb.supplementation' }],
    },
  },
  {
    path: '/supplementation/:supplementId',
    name: 'supplement-detail',
    beforeEnter: Auth0.routeGuard,
    component: () => import('../views/SupplementDetailPage.vue'),
    meta: {
      breadcrumb: [{ parent: 'breadcrumb.supplementation', label: '' }],
    },
  },
  {
    path: '/foods',
    name: 'foods',
    beforeEnter: Auth0.routeGuard,
    component: () => import('../views/FoodsPage.vue'),
    meta: {
      breadcrumb: [{ parent: 'breadcrumb.foods', label: 'breadcrumb.foods' }],
    },
  },
  {
    path: '/foods/:foodId',
    name: 'food-detail',
    beforeEnter: Auth0.routeGuard,
    component: () => import('../views/FoodDetailPage.vue'),
    meta: {
      breadcrumb: [{ parent: 'breadcrumb.foods', label: '' }],
    },
  },
  {
    path: '/error/:type',
    component: () => import('../views/ErrorPage.vue'),
    children: [
      {
        path: ':info',
        component: () => import('../views/ErrorPage.vue'),
      },
    ],
  },
  { path: '/:pathMatch(.*)', redirect: '/error/404' },
];

const router = createRouter({
  //history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
});

router.beforeEach((_to, _from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;
